import React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import Img from "gatsby-image";
import styled from "styled-components";
// Components
import { SEO } from "../components/SEO";
import { TwoColumnContentImage } from "../components/TwoColumnContentImage";
import { Box, Button, Center, Flex, Heading, Box as Section, Text } from "@chakra-ui/react";

const MotionBox = motion(Box);

const HomePage = ({ data }) => {
  return (
    <>
      <SEO title="Home" />

      <Section
        marginTop="0"
        minHeight="100vh"
        position="relative"
        overflow="hidden"
        background="radial-gradient(circle at bottom right, #36312b 0, black)"
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <MotionBox
          position="absolute"
          w="100%"
          bottom="0"
          right="0"
          initial={{
            opacity: 0,
            y: "100%",
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 3.8,
            delay: 2.4,
            type: "spring",
            stiffness: 80,
          }}
        >
          <HeroImage fluid={data.main_background.childImageSharp.fluid} />
        </MotionBox>
        <Flex
          flexDirection="column"
          justifyContent={["start", null, null, "center"]}
          alignItems={["center", null, null, "start"]}
          p={6}
          pt={12}
        >
          <MotionBox
            w="100%"
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 0.8,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Heading
              as="h3"
              color="brand.main"
              textTransform="uppercase"
              textAlign={["center", "left"]}
              fontSize={["5xl", null, null, "7xl"]}
              mt={[28, null, null, 0]}
              fontWeight="bold"
              textShadow="2px 2px 2px var(--color-black)"
              lineHeight="1"
              letterSpacing="0.01em"
            >
              BONAFIDE
            </Heading>
            <Heading
              as="h3"
              color="brand.main"
              textTransform="uppercase"
              textAlign={["center", "left"]}
              fontSize={["3xl", null, null, "5xl"]}
              fontWeight="semibold"
              textShadow="2px 2px 2px var(--color-black)"
              lineHeight="1"
              letterSpacing="0.01em"
            >
              PIXEL PUSHER
            </Heading>
          </MotionBox>
          <MotionBox
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.2,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Text
              mt={[4]}
              fontWeight="bold"
              color="white"
              fontSize={["lg", null, null, "xl"]}
              textAlign={["center", "left"]}
              lineHeight={1.5}
            >
              I am an{" "}
              <Text as="i" color="orange.300">
                always curious
              </Text>
              , strategic-creative digital creator
            </Text>
          </MotionBox>
          <MotionBox
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.6,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Box mt={12}>
              <Link to="/contact">
                <Button
                  fontSize="lg"
                  px={5}
                  py={4}
                  textTransform="uppercase"
                  letterSpacing="wider"
                  color="white"
                  bg="brand.main"
                  _hover={{ background: "brand.darkAccent" }}
                >
                  Holla at Me
                </Button>
              </Link>
            </Box>
          </MotionBox>
        </Flex>
      </Section>

      <Section
        backgroundColor="brand.lightShades"
        py={4}
        backgroundImage="linear-gradient(
0deg, var(--color-gray-100), var(--color-gray-200))"
      >
        <Center p={6} maxWidth="container.lg" minHeight={72} marginX="auto">
          <Box lineHeight="1.75">
            <Text color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              My name is Noah Wong. I am a marketer, web developer, and tech aficiando. I currently work at Amazon Web Services (AWS) where
              I help build cool stuff.
            </Text>
            <Text color="brand.darkShades" fontSize={["md", null, null, "lg"]}>
              I develop marketing campaigns, websites and web apps &mdash; mainly for work but also for fun &mdash; so I always try to keep
              up on the latest technologies and tools.
            </Text>
          </Box>
        </Center>
      </Section>

      <TwoColumnContentImage fluid={data.oceangate.childImageSharp.fluid} backgroundColor="var(--color-primary)">
        <Heading fontSize={["2xl", null, null, "3xl"]}>36 Chambers</Heading>
        <Text mt={4}>
          This is my studio. My palace. My digs. My spot on the internet where I show some stuff. Build some stuff. Say some stuff.
        </Text>
        <Text mt={4}>
          Works include code and more code, thoughts, professional work history, moments in my personal life, cool things I want to show
          off, or whatever I feel like sharing with the world.
        </Text>

        <Box mt={4}>
          <Link to="/about">
            <Button variant="outline" color="brand.lightShades" _hover={{ color: "brand.lightAccent" }}>
              Enter the Dragon
            </Button>
          </Link>
        </Box>
      </TwoColumnContentImage>
    </>
  );
};

// Styled Components
const HeroImage = styled(Img)<HeroImageProps>`
  position: absolute !important;
  overflow: hidden;
  right: 50%;
  bottom: 0px;
  transform: translateX(50%);
  width: 0%;
  filter: drop-shadow(2.5rem 2.5rem 3.5rem var(--color-black));

  @media (min-width: 375px) {
    width: 80%;
  }
  @media (min-width: 768px) {
    width: 500px;
  }
  @media (min-width: 375px) and (max-height: 667px) {
    width: 275px;
  }
  @media (min-width: 411px) and (max-height: 731px) {
    width: 325px;
  }
  @media (min-width: 414px) and (max-height: 736px) {
    width: 325px;
  }
  @media (min-width: 1024px) and (max-height: 768px) {
    width: 300px;
  }
  @media (min-width: 1112px) and (max-height: 834px) {
    width: 350px;
  }

  @media ${({ theme }) => theme.mq.lg} {
    right: 0px;
    bottom: 0px;
    transform: translateX(0%);
    max-width: 600px;
  }
`;

// Types
type HeroImageProps = {
  fluid?: any;
};

export const pageQuery = graphql`
  query {
    main_background: file(relativePath: { eq: "hero_background.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oceangate: file(relativePath: { eq: "ocean-gate.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HomePage;
