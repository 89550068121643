import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
// Components

const Section = ({
  children,
  className,
  backgroundColor,
  backgroundImage,
  minHeight,
  maxHeight,
  marginTop,
  marginBottom,
  overflow,
  paddingTop = "5rem",
  paddingLeft = "0",
  paddingRight = "0",
  paddingBottom = "5rem",
  position = "relative",
  style,
}: SectionProps) => {
  return (
    <StyledSection
      backgroundColor={backgroundColor}
      className={className}
      minHeight={minHeight}
      maxHeight={maxHeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      overflow={overflow}
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      position={position}
      style={style}
    >
      <BackgroundImage fluid={backgroundImage} />
      {children}
    </StyledSection>
  );
};

// Styled Components
const StyledSection = styled.section<SectionProps>`
  &&& {
    width: 100%;
    min-height: ${({ minHeight }) => minHeight};
    max-height: ${({ maxHeight }) => maxHeight};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    padding-top: ${({ paddingTop }) => paddingTop};
    padding-left: ${({ paddingLeft }) => paddingLeft};
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: ${({ position }) => position};
    overflow: ${({ overflow }) => overflow};
  }
`;
const BackgroundImage = styled(Img)<BackgroundImageProps>`
  position: absolute !important;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

// Types
type SectionProps = {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  backgroundImage?: any;
  minHeight?: string;
  maxHeight?: string;
  marginTop?: string;
  marginBottom?: string;
  overflow?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  position?: string;
  style?: React.CSSProperties;
};
type BackgroundImageProps = {
  fluid: any;
};

export { Section };
