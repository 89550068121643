import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
// Components
import { Section } from "../Section";
import Img from "gatsby-image";
import { FadeIn } from "../FadeIn";

const TwoColumnContentImage: React.FC<TwoColumnContentImageProps> = ({ children, fluid, backgroundColor }) => {
  return (
    <SectionWrapper paddingTop="0" paddingBottom="0">
      <TwoColumnsContainer backgroundColor={backgroundColor}>
        <TwoColumnsContainerContent>
          <TwoColumnsContainerBox>
            <FadeIn>{children}</FadeIn>
          </TwoColumnsContainerBox>
        </TwoColumnsContainerContent>
        <BackgroundImage fluid={fluid} />
      </TwoColumnsContainer>
    </SectionWrapper>
  );
};

// Styled Components
const SectionWrapper = styled(Section)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    inset: 1em;
    border: 1px solid var(--color-gray-100);
    border-radius: 2px;
    z-index: 0;
  }
`;
const TwoColumnsContainer = styled.div<TwoColumnsContainerProps>`
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "var(--color-gray-900)")};
  color: var(--color-white);
  position: relative;

  @media ${({ theme }) => theme.mq.md} {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4em 0px;
  }
`;
const TwoColumnsContainerContent = styled.div<TwoColumnsContainerContentProps>`
  max-width: var(--screen-lg);
  margin: 0px auto;
  width: 100%;
  position: relative;
  justify-content: space-between;
  padding: 3rem 2.8rem;
  z-index: 1;

  @media ${({ theme }) => theme.mq.md} {
    padding: 3rem 1.6rem;
    display: flex;
  }
`;
const TwoColumnsContainerBox = styled(motion.div)<TwoColumnsContainerBoxProps>`
  text-align: left;
  padding: 0px;
  flex: 0 0 48%;
`;
const BackgroundImage = styled(Img)<BackgroundImageProps>`
  overflow: hidden;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  @media ${({ theme }) => theme.mq.md} {
    position: absolute !important;
    width: 50%;
  }
`;
// Types
type TwoColumnContentImageProps = {
  children: React.ReactNode | string;
  fluid?: any;
  backgroundColor?: string;
};
type TwoColumnsContainerBoxProps = {
  initial?: Object;
  animate?: Object;
  transition?: any;
};
type TwoColumnsContainerProps = {
  backgroundColor?: string;
};
type TwoColumnsContainerContentProps = {
  width?: number;
};
type BackgroundImageProps = {
  fluid: any;
};

export { TwoColumnContentImage };
